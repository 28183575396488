//Plugin
import Vue from 'vue'
import Vuex from 'vuex'

//Share
import alert from './alert'
import status from './status'
import summaries from './summaries'
import rules from './rules'
import note from './note'

//Components
import register from './register'
import login from './login'
import user from './user'
import dashboard from './dashboard'
import areas from './areas'
import user_areas from './areas/users'
import contract_years from './contract/years'
import employee from './employee'
import charge from './charge'
import annexe from './annexe'
import contract from './contract'
import supervisor from './supervisor'
import report from './report'
import approval from './approval'
import evidence from './evidence'
import bill from './bill'
import configuration from './configuration'
import typeActivity from './typeActivity'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    //Share
    alert,
    status,
    summaries,
    rules,
    note,
    
    //Components
    register,
    login,
    user,  
    dashboard,
    areas,
    user_areas,
    contract_years,
    employee,
    charge,
    annexe,
    contract,
    supervisor,
    report,
    approval,
    evidence,
    bill,
    configuration,
    typeActivity
  }
})
