import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_BILLS = 'SET_BILLS';
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        isLoadingReport: false,
        bills: [],
        bills_by_report: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_BILLS](state, bills) {
            state.bills = bills;
        },
        [SET_LOADING_REPORT](state, isLoadingReport) {
            state.isLoadingReport = isLoadingReport;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getBills({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("bills?" + filters +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_BILLS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },    
        
        getBillsAll({ commit }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING_REPORT, true);
            return axios
                .get("bills?" + filters,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING_REPORT, false);
                    return response.data
                })
                .catch((error) => {
                    commit(SET_LOADING_REPORT, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },    

        nextPageBills({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getBills');
        }
    },
};
