import axios from "axios";
import { SET_ALERT } from "../alert"
import router from "../../router"
import bills from "./bills"

export const SET_BILL = 'SET_BILL';
export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        report: null,
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_BILL](state, bill) {
            state.bill = bill;
        },
    },
    actions: {
        getBill({ commit }, id) {
            axios
                .get("bills/" + id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then((response) => {
                    commit(SET_BILL, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createBill({ commit }, form) {
            commit(SET_LOADING, true);
            axios
                .post("bills", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cuenta de cobro creada correctamente",
                        type: 'success'
                    });
                    window.open(response.data.bill_url, "_blank");
                    router.push({ path: "/bills" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateBill({ commit, dispatch }, payload) {
            if (!payload.form._method) {
                payload.form._method = "put";
            }
            commit(SET_LOADING, true);
            axios
                .post("bills/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cuenta de cobro editada correctamente",
                        type: 'success'
                    });                    
                    if (router.currentRoute.name == "BillShow") {
                        dispatch("getBill", router.currentRoute.params.id);
                    } else {                        
                        dispatch('getBills');
                    }
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteBill({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("bills/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cuenta de cobro eliminada correctamente",
                        type: 'success'
                    });   
                    dispatch('getBills');                 
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        bills
    }
};
