import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_CONTRACTS_YEARS = 'SET_CONTRACTS_YEARS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        years: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_CONTRACTS_YEARS](state, years) {
            state.years = years;
        },
    },
    actions: {
        getContractYears({ commit }) {

            commit(SET_LOADING, true);
            axios
                .get("contract_years",
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                console.log(response.data);
                
                    commit(SET_LOADING, false);
                    commit(SET_CONTRACTS_YEARS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },        

    },
};
