import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        employees: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_EMPLOYEES](state, employees) {
            state.employees = employees;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getEmployees({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            console.log(filters);
            
            axios
                .get("employees?" + filters +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                console.log(response.data);

                    commit(SET_LOADING, false);
                    commit(SET_EMPLOYEES, response.data);                    
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        nextPageEmployees({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getEmployees');
        }
    }   
};
