import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
    },
    actions: {
        createSupervisor({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("supervisors", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Supervisor agregado correctamente",
                        type: 'success'
                    });
                    dispatch("getContract", router.currentRoute.params.id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },        

        deleteSupervisor({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("supervisors/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Supervisor eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getContract', router.currentRoute.params.id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
};
