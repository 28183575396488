import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_NAME_SUMMARY = 'SET_NAME_SUMMARY';

export default {
    state: {
        name_summary: {},
    },
    mutations: {
        [SET_NAME_SUMMARY](state, name_summary) {
            state.name_summary = name_summary;
        },
    },
    actions: {
        getNameSummary({ commit }, filters) {
            axios
                .get("api/name_summary?" + filters)
                .then((response) => {
                    commit(SET_NAME_SUMMARY, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
};
