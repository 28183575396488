import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"
import user from "../user"

//Components
import employees from "./employees"

export const SET_LOADING = 'SET_LOADING';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';

export default {
    state: {
        employee: null,
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_EMPLOYEE](state, employee) {
            state.employee = employee;
        },
    },
    actions: {
        getEmployee({ commit }, id) {
            commit(SET_LOADING, true);

            axios
                .get("employees/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_EMPLOYEE, response.data);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        editEmployee({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);


            axios
                .post("employees/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: 'Colaborador editado correctamente',
                        type: 'success'
                    });                    
                    if (router.currentRoute.name == "EmployeeShow") {                        
                        dispatch("getEmployee", router.currentRoute.params.id);
                    } else {
                        if (user.state.user.role == 'EMPLOYEE') {
                            router.push({ path: "/profile" });
                        } else {
                            if (router.currentRoute.name == "AreaShow") {                        
                       dispatch("getUsersArea");
                    } else {dispatch("getEmployees");}
                        }
                    }
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    var message;
                    error.response.data.errors ?
                        message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                        message = error.response.data.message;
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        });
                    }
                });
        },

        deleteEmployee({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("employees/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Colaborador eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getEmployees');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        employees
    }
};
