import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_CHARGES = 'SET_CHARGES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        charges: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_CHARGES](state, charges) {
            state.charges = charges;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getCharges({ commit, state }, filters) {
            if (filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("charges?" + filters + "page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_CHARGES, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getChargesAll({ commit }) {
            commit(SET_LOADING, true);
            axios
                .get("charges",
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_CHARGES, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        nextPageCharges({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getCharges');
        }
    },
};
