import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_REPORTS = 'SET_REPORTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        reports: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_REPORTS](state, reports) {
            state.reports = reports;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getReports({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("reports?" + filters +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_REPORTS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },        

        nextPageReports({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getReports');
        }
    },
};
