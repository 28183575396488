import axios from "axios";
import { SET_ALERT } from "../alert"
import users from "./users"

export const SET_USER = 'SET_USER';

export default {
    state: {
        user: null,
    },
    mutations: {
        [SET_USER](state, user) {
            state.user = user;
        }
    },
    actions: {
        getUser({ commit }) {
            axios
                .get("me", {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then((response) => {                    
                    commit(SET_USER, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
    modules: {
        users
    }
};
