import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/auth/Register.vue')
  },
  {
    path: '/tratamiento_datos_personales',
    name: 'Term',
    component: () => import('../views/auth/Term.vue')
  },
  {
    path: '/',
    component: () => import('../views/layout/Layout.vue'),
    meta: { requiresAuth: true },
    children: [
      //Dashboard
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Dashboard.vue'),
      },
      //Users
      {
        path: '/users',
        name: 'Users',
        component: () => import('../views/user/Users.vue'),
      },
      //Areas
      {
        path: '/areas',
        name: 'Areas',
        component: () => import('../views/areas/Areas.vue'),
      },
      {
        path: '/areas/show/:id',
        name: 'AreaShow',
        component: () => import('../views/areas/Show.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('../views/user/Profile.vue'),
      },
      {
        path: '/profile/edit',
        name: 'ProfileEdit',
        component: () => import('../views/employee/Edit.vue'),
      },
      //Employees
      {
        path: '/employees',
        name: 'Employees',
        component: () => import('../views/employee/Employees.vue'),
      },
      {
        path: '/employees/show/:id',
        name: 'EmployeeShow',
        component: () => import('../views/employee/Show.vue'),
      },
      //Charges
      {
        path: '/charges',
        name: 'Charges',
        component: () => import('../views/charge/Charges.vue'),
      },
      {
        path: '/charges/create',
        name: 'ChargeCreate',
        component: () => import('../views/charge/Create.vue'),
      },
      {
        path: '/charges/show/:id',
        name: 'ChargeShow',
        component: () => import('../views/charge/Show.vue'),
      },
      {
        path: '/charges/edit/:id',
        name: 'ChargeEdit',
        component: () => import('../views/charge/Edit.vue'),
      },
     //Contract
      {
        path: '/contracts',
        name: 'ContractsYears',
        component: () => import('../views/contract/ContractsYear.vue'),
      },
      {
        path: '/contracts/create',
        name: 'ContractCreate',
        component: () => import('../views/contract/Create.vue'),
      },
       {
        path: '/contracts/:id',
        name: 'Contracts',
        component: () => import('../views/contract/Contracts.vue'),
      },
      {
        path: '/contracts/show/:id',
        name: 'ContractShow',
        component: () => import('../views/contract/Show.vue'),
      },
      //Report
      {
        path: '/reports',
        name: 'Reports',
        component: () => import('../views/report/Reports.vue'),
      },
      {
        path: '/contracts/:contract/reports/create',
        name: 'ReportCreate',
        component: () => import('../views/report/Create.vue'),
        props: true
      },
      {
        path: '/reports/show/:id',
        name: 'ReportShow',
        component: () => import('../views/report/Show.vue'),
        props: true
      },
      {
        path: '/contracts/:contract/reports/edit/:id',
        name: 'ReportEdit',
        component: () => import('../views/report/Create.vue'),
        props: true
      },
      //Bill
      {
        path: '/bills',
        name: 'Bills',
        component: () => import('../views/bill/Bills.vue'),
      },
      {
        path: '/bills/show/:id',
        name: 'BillShow',
        component: () => import('../views/bill/Show.vue'),
        props: true
      },
      //Configuration
      {
        path: '/configurations',
        name: 'Configuration',
        component: () => import('../views/configuration/Configuration.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

//Validate auth
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem('access_token')) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next();
  }
});

export default router
