import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_INVALID = 'SET_INVALID';
export const SET_ERROR = 'SET_ERROR';

export default {
    state: {
        isLoading: false,
        isInvalid: false,
        error: null,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_INVALID](state, invalid) {
            state.isInvalid = invalid;
        },
        [SET_ERROR](state, error) {
            state.error = error;
        }
    },
    actions: {
        async register({ commit }, form) {
            commit(SET_LOADING, true);

            await axios
                .post("employees", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: 'Registro realizado correctamente',
                        type: 'success'
                    });
                    router.push({ path: "/login" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    var message;
                    error.response.data.errors ?
                        message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                        message = error.response.data.message;
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        });
                    }
                });
        }
    },
};
