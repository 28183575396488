import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueExcelXlsx from "vue-excel-xlsx";

Vue.config.productionTip = false

axios.defaults.baseURL = 'http://54.84.186.160/api/'
//http://localhost/cms-corporation/public/api/
//https://api.corporacionlive.com/api
//http://54.84.186.160/api/

if(localStorage.getItem('access_token')){
  store.dispatch('getUser');
}

// Init Excel
Vue.use(VueExcelXlsx);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
