import axios from "axios";
import { SET_ALERT } from "../../alert"

import router from "../../../router"

export const SET_LOADING = 'SET_LOADING';
export const SET_USERS = 'SET_USERS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_USERS_QUERY = 'SET_USERS_QUERY';
export const SET_LOADING_QUERY = 'SET_LOADING_QUERY';

export default {
    state: {
        isLoading: false,
        isLoadingQuery: false,
        users: [],
        users_query: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_LOADING_QUERY](state, loading_query) {
            state.isLoadingQuery = loading_query;
        },
        [SET_USERS](state, users) {
            state.users = users;
        },
        [SET_USERS_QUERY](state, users_query) {
            state.users_query = users_query;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getUsersArea({ commit, state }, filtros) {

            commit(SET_LOADING, true);


            filtros = filtros == undefined ? '' : filtros;
            
            axios
                .get("employees?area=" + router.currentRoute.params.id + "&" + filtros +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
console.log(response.data);

                    commit(SET_LOADING, false);
                    commit(SET_USERS, response.data);                    
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
        updateUserArea({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            console.log(payload);
        
           axios
                .put("employees/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: payload.form.area == 0 ? 'Colaborador retirado del area correctamente' : 'Colaborador agregado al area correctamente',
                        type: 'success'
                    });                    
                     dispatch("getUsersArea");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    var message;
                    error.response.data.errors ?
                        message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                        message = error.response.data.message;
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        });
                    }
                });
        },
        nextPageEmployees({ commit, dispatch }, payload) {
            commit(SET_CURRENT_PAGE, payload.page);
            dispatch('getUsersArea');
        },
        
        queryEmployee({ commit }, query) {
            console.log(query);
                        commit(SET_LOADING_QUERY, true);
                   axios
                .get("employees?search=" + query +"&page=1",
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {

			console.info(response.data);

                    commit(SET_USERS_QUERY, response.data);                    
                    commit(SET_LOADING_QUERY, false);

                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING_QUERY, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
                
        },
        
       downloadExcel({ commit }) {
            console.log(router.currentRoute.params.id);
             commit(SET_LOADING, true);
           
                    axios
                .get("gen_excel?area=" + router.currentRoute.params.id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                


		var fileURL = window.URL.createObjectURL(new Blob([response.data.data]));
                var fURL = document.createElement('a');
   
                     fURL.href = fileURL;
                     fURL.setAttribute('download', response.data.filename+'.csv');
                     document.body.appendChild(fURL);
   
                     fURL.click();
                     
                    commit(SET_LOADING, false);
                      
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
                
                
        }
    }   
};
