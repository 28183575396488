import axios from "axios";
import { SET_ALERT } from "../../alert"
import router from "../../../router"

export const SET_LOADING = 'SET_LOADING';
export const SET_CONTRACTS = 'SET_CONTRACTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        contracts: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_CONTRACTS](state, contracts) {
            state.contracts = contracts;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getContracts({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("contracts?year=" +router.currentRoute.params.id + "&" + filters +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_CONTRACTS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },        

    downloadContractsExcel({ commit }) {
            console.log(router.currentRoute.params.id);
             commit(SET_LOADING, true);
           
                    axios
                .get("gen_contracts_excel?year=" + router.currentRoute.params.id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                


		var fileURL = window.URL.createObjectURL(new Blob([response.data.data]));
                var fURL = document.createElement('a');
   
                     fURL.href = fileURL;
                     fURL.setAttribute('download', response.data.filename+'.csv');
                     document.body.appendChild(fURL);
   
                     fURL.click();
                     
                    commit(SET_LOADING, false);
                      
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
                
                
        },
        
        nextPageContracts({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getContracts');
        }
    },
};
