import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_AREAS = 'SET_AREAS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        areas: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_AREAS](state, areas) {
            state.areas = areas;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getAreas({ commit, state }, filters) {
            if (filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("areas?" + filters + "page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_AREAS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        nextPageAreas({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getAreas');
        },
        
      createArea({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("areas", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Area creada correctamente",
                        type: 'success'
                    });
                    dispatch('getAreas');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

          updateArea({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .put("areas/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Area editada correctamente",
                        type: 'success'
                    });
                    dispatch('getAreas');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteArea({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("areas/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Area eliminada correctamente",
                        type: 'success'
                    });
                    dispatch('getAreas');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
};
