import axios from "axios";
import { SET_ALERT } from "../alert"
import router from "../../router"
import reports from "./reports"

export const SET_REPORT = 'SET_REPORT';
export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        report: null,
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_REPORT](state, report) {
            state.report = report;
        },
    },
    actions: {
        getReport({ commit }, id) {
            axios
                .get("reports/" + id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then((response) => {
                    commit(SET_REPORT, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createReport({ commit }, form) {
            commit(SET_LOADING, true);
            axios
                .post("reports", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Informe creado correctamente",
                        type: 'success'
                    });
                    router.push({ path: "/reports" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateReport({ commit, dispatch }, payload) {
            if (!payload.form._method) {
                payload.form._method = "put";
            }
            commit(SET_LOADING, true);
            axios
                .post("reports/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Informe editado correctamente",
                        type: 'success'
                    });
                    if(router.currentRoute.name == "ReportShow"){
                        dispatch("getReport", router.currentRoute.params.id);
                    }else{
                        dispatch("getReports");
                    }  
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteReport({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("reports/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Informe eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getReports');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        createReportPdf({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("reports_pdf", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Reporte PDF creado correctamente",
                        type: 'success'
                    });
                    dispatch("getReport", router.currentRoute.params.id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        reports
    }
};
