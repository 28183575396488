import axios from "axios";
import { SET_ALERT } from "../alert"
import router from "../../router"
import charges from "./charges"

export const SET_CHARGE = 'SET_CHARGE';
export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        charge: null,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_CHARGE](state, charge) {
            state.charge = charge;
        },
    },
    actions: {
        getCharge({ commit }, id) {
            axios
                .get("charges/" + id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then((response) => {                    
                    commit(SET_CHARGE, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createCharge({ commit }, form) {
            commit(SET_LOADING, true);
            axios
                .post("charges", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cargo creado correctamente",
                        type: 'success'
                    });
                    router.push({ path: "/charges" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateCharge({ commit }, payload) {
            commit(SET_LOADING, true);
            axios
                .put("charges/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cargo editado correctamente",
                        type: 'success'
                    });
                    router.push({ path: "/charges" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteCharge({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("charges/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cargo eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getCharges');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        charges
    }
};
