import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify);

const theme = {
    primary: '#2196F3',
    secondary: '#2196F3',
    accent: '#263238',
    info: '#2196F3',
}

export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
    },
});
