<template>
  <div>
    <v-snackbar
      v-model="show"
      bottom="bottom"
      :color="this.$store.state.alert.type"      
      right="right"
      timeout="6000"
    >
      {{ this.$store.state.alert.message }}
      <template v-slot:action="{ attrs }">
        <v-btn 
          dark 
          text 
          v-bind="attrs" 
          @click="show = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { SET_ALERT } from '@/store/alert';
export default {
  name: "MessageAlert", 
  data() {
    return {
      show: false
    }
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === SET_ALERT){
        this.show = true;
      }
    }); 
  }
};
</script>

<style scoped>
</style>
