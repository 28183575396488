import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
    },
    actions: {
        createNote({ commit, dispatch }, form) {
            commit(SET_LOADING, true);

            axios
                .post("notes", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Nota agregada correctamente",
                        type: 'success'
                    });
                    dispatch("getUser");      
                    if(router.currentRoute.name == "ReportShow"){
                        dispatch("getReport", router.currentRoute.params.id);
                    }else if(router.currentRoute.name == "BillShow"){
                        dispatch("getBill", router.currentRoute.params.id);
                    }else{
                        dispatch("getEmployee", router.currentRoute.params.id);
                    }                               
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
};
