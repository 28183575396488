import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        }
    },
    actions: {
        async login({ commit, dispatch }, form) {
            commit(SET_LOADING, true);

            await axios
                .post("sessions", form)
                .then((response) => {
                    //Set Loading
                    commit(SET_LOADING, false);
                    //Save token in local storage
                    localStorage.setItem("access_token", response.data.access_token);
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    //Get user data
                    dispatch("getUser");
                    //Validate if user is employee and redirect if is need
                    if (response.data.user.employee) {
                        //Validate if user is inactive
                        if (response.data.user.employee.status == "INACTIVE") {
                            commit(SET_ALERT, {
                                message: "Usuario desactivado, comuniquese con el administrador",
                                type: 'error'
                            });
                            return;
                        }
                        //Redirect if is need
                        if (response.data.user.employee.status !== "ACTIVE") {
                            router.push({ path: "/profile" });
                        } else {
                            router.push({ path: "/" });
                        }
                    } else {
                        router.push({ path: "/" });
                    }
                })
                .catch((error) => {                    
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        logout({ commit }) {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            commit(SET_ALERT, {
                message: "Salio de la aplicación",
                type: "success"
            });
            router.push({ path: "/login" });
        },
    },
};
