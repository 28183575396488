export default {
    state: {
        required: (value) => !!value || "Campo obligatorio",
        email: (v) => /.+@.+\..+/.test(v) || "Debe ser un correo valido",
        password: (v) => !v || v.length >= 8 || "Mínimo 8 caracteres",
        file: (value) => !value || "Documento obligatorio",
        max_file: (value) => !value || value.size < 10000000 || 'El documento debe pesar menos de 10MB',
        max_activities: value => (value || '').length <= 17000 || 'Maximo puede tener 17.000 caracteres',
        max_achievements: value => (value || '').length <= 3000 || 'Maximo puede tener 3.000 caracteres',
        max_conclusions: value => (value || '').length <= 4000 || 'Maximo puede tener 4.000 caracteres',
    },
};
