import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_USERS = 'SET_USERS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        users: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_USERS](state, users) {
            state.users = users;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getUsers({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("users?" + filters +"page=" + state.currentPage,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_USERS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getUsersAll({ commit }) {
            commit(SET_LOADING, true);
            axios
                .get("users",
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_USERS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createUser({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("users", form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Usuario creado correctamente",
                        type: 'success'
                    });
                    dispatch('getUsers');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateUser({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .put("users/" + payload.id, payload.form,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Usuario editado correctamente",
                        type: 'success'
                    });
                    dispatch('getUsers');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteUser({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("users/" + id,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                        }
                    })
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Usuario eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getUsers');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        nextPageUsers({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getUsers');
        }
    },
};
