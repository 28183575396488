import axios from "axios";
import { SET_ALERT } from "../alert"
import router from "../../router"

export default {
    actions: {
        createEvidence({ commit, dispatch }, form) {
            axios
                .post("evidences", form, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then(() => {
                    commit(SET_ALERT, {
                        message: "Evidencia guardada correctamente",
                        type: 'success'
                    });
                    dispatch("getReport", router.currentRoute.params.id);
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        deleteEvidence({ commit, dispatch }, id) {
            axios
                .delete("evidences/" + id, {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    }
                })
                .then(() => {
                    commit(SET_ALERT, {
                        message: "Evidencia eliminada correctamente",
                        type: 'success'
                    });
                    dispatch("getReport", router.currentRoute.params.id);
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
};
