export default {
    state: {
        roleUser: [
            {
                text: "ADMINISTRADOR",
                value: "ADMINISTRATOR"
            },
            {
                text: "SUPERVISOR",
                value: "REVIEWER"
            },
            {
                text: "COLABORADOR",
                value: "EMPLOYEE"
            },
        ],
        roleUserAdmin: [
            {
                text: "ADMINISTRADOR",
                value: "ADMINISTRATOR"
            },
            {
                text: "SUPERVISOR",
                value: "REVIEWER"
            },
        ],
        employeeStatus: [
            {
                text: "ACTIVO",
                value: "ACTIVE"
            },
            {
                text: "PENDIENTE",
                value: "PENDING"
            },
            {
                text: "CORRECCIÓN",
                value: "CORRECTION"
            },
            {
                text: "INACTIVO",
                value: "INACTIVE"
            },
        ],
        bloodTypes: [
            { text: "O-" },
            { text: "O+" },
            { text: "A-" },
            { text: "A+" },
            { text: "B-" },
            { text: "B+" },
            { text: "AB-" },
            { text: "AB+" },
        ],
        chargePermissions: [
            {
                text: "SI",
                value: "1"
            },
            {
                text: "NO",
                value: "0"
            },
        ],
        contractStatus: [
            {
                text: "ACTIVO",
                value: "ACTIVE"
            },
            {
                text: "FINALIZADO",
                value: "COMPLETED"
            },
            {
                text: "INACTIVO",
                value: "INACTIVE"
            },
        ],
        supervisorType: [
            {
                text: "SUPERVISOR",
                value: "SUPERVISOR"
            },
            {
                text: "COLABORADOR",
                value: "COLLABORATOR"
            },
        ],
        reportStatus: [
            {
                text: "PENDIENTE",
                value: "PENDING"
            },
            {
                text: "APROBADO",
                value: "APPROVED"
            },
            {
                text: "CANCELADO",
                value: "CANCELED"
            },
        ],
        billStatus: [
            {
                text: "CORRECCIÓN",
                value: "CORRECTION"
            },
            {
                text: "PENDIENTE ABROBACIÓN",
                value: "PENDING_APPROVAL"
            },
            {
                text: "PENDIENTE PAGO",
                value: "PENDING_PAYMENT"
            },
            {
                text: "PAGADO",
                value: "PAID"
            },
            {
                text: "CANCELADO",
                value: "INACTIVE"
            },
        ],
        configurationStatus: [
            {
                text: "ACTIVO",
                value: "ACTIVE"
            },
            {
                text: "INACTIVO",
                value: "INACTIVE"
            },
        ],
        typeActivity: [
            {
                text: "Clases",
                value: "PRIMARY"
            },
            {
                text: "Otras Actividades",
                value: "OTHER"
            },
        ],
        typeStatistics: [
            {
                text: "Interno",
                value: "INTERNAL"
            },
            {
                text: "Externo",
                value: "EXTERNAL"
            },
        ],
        datePiker: {
            model: null,
            label: "SELECCIONE EL RANGO DE FECHA *",
            format: "YYYY-MM-DD",
            outputFormat: "YYYY-MM-DD",
            formatted: "ll",
            shortcut: [
                { key: "now", label: "Hoy", value: 0 },
                { key: "thisWeek", label: "Esta semana", value: "isoWeek" },
                { key: "last7Days", label: "Ultimos 7 dias", value: 7 },
                { key: "last30Days", label: "Ultimos 30 dias", value: 30 },
                { key: "thisMonth", label: "Este mes", value: "month" },
                { key: "lastMonth", label: "Ultimo mes", value: "-month" },
                { key: "thisYear", label: "Este año", value: "year" },
            ],
        },
    },
    getters: {
        getRoleUser: (state) => (role) => {
            return state.roleUser.find(obj => obj.value == role).text;
        },
        getEmployeeStatus: (state) => (status) => {
            return state.employeeStatus.find(obj => obj.value == status).text;
        },
        getEmployeeStatuColor: () => (status) => {
            switch (status) {
                case 'ACTIVE':
                    return 'green';
                case 'PENDING':
                    return 'yellow accent-4';
                case 'CORRECTION':
                    return 'orange';
                case 'INACTIVE':
                    return 'red';
            }
        },
        getContractStatus: (state) => (status) => {
            return state.contractStatus.find(obj => obj.value == status).text;
        },
        getContractStatuColor: () => (status) => {
            switch (status) {
                case 'COMPLETED':
                    return 'green';
                case 'ACTIVE':
                    return 'yellow accent-4';
                case 'INACTIVE':
                    return 'red';
            }
        },
        getSupervisorType: (state) => (type) => {
            return state.supervisorType.find(obj => obj.value == type).text;
        },
        getReportStatuColor: () => (status) => {
            switch (status) {
                case 'PENDING':
                    return 'yellow accent-4';
                case 'APPROVED':
                    return 'green';
                case 'CANCELED':
                    return 'red';
            }
        },
        getReportStatus: (state) => (status) => {
            return state.reportStatus.find(obj => obj.value == status).text;
        },
        getBillStatus: (state) => (status) => {
            return state.billStatus.find(obj => obj.value == status).text;
        },
        getBillStatuColor: () => (status) => {
            switch (status) {
                case 'PENDING_APPROVAL':
                case 'PENDING_PAYMENT':
                    return 'yellow accent-4';
                case 'PAID':
                    return 'green';
                case 'INACTIVE':
                case 'CORRECTION':
                    return 'red';
            }
        },
        getConfigurationStatus: (state) => (status) => {
            return state.contractStatus.find(obj => obj.value == status).text;
        },
        getConfigurationStatuColor: () => (status) => {
            switch (status) {              
                case 'ACTIVE':
                    return 'yellow accent-4';
                case 'INACTIVE':
                    return 'red';
            }
        },
        getTypeActivities: (state) => (status) => {
            return state.typeActivity.find(obj => obj.value == status).text;
        },
        getTypeStatistics: (state) => (status) => {
            return state.typeStatistics.find(obj => obj.value == status).text;
        },
    },
};
